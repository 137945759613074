<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center">
      <CCol md="6">
        <div class="">
          <div class="error-wrap">
            <p class="error-title">
              У вас нет доступа к данному разделу или сайту.
            </p>
            <!--            <p class="error-title">You do not have access to this page.</p>-->
            <p class="error-title">
              Пожалуйста, авторизуйтесь под другой учетной записью.
            </p>
            <!--            <p class="error-title">Please log in with another account.</p>-->

            <button
              @click="logout"
              class="btn btn-danger m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary logout"
            >
              Выйти
            </button>
          </div>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Page403",

  data: () => ({
    showEntityModal: false,
  }),

  methods: {
    ...mapActions("account", ["logout"]),
  },
};
</script>

<style lang="sass" scoped>

.error-wrap
  display: flex
  flex-direction: column
  gap: 0.75rem
  width: 35%
  margin: 0 auto

.error-title
  font: 400 1.5rem/144% Futura FuturisC
  color: #4e4e4e
  text-align: center
  display: inline-block


.logout
  border-radius: 0
  font: 600 1.5rem/144% 'Futura FuturisC Bold'
  border-width: 1px
  padding: 0.75rem
  text-transform: uppercase
  position: relative
  background-color: #e2173d
  font-size: 1rem
  color: #fff
  outline: none
  cursor: pointer
  width: 100%
  z-index: 1
  transition: all .6s linear

  margin-top: 1.5rem
</style>
